import axios, { AxiosResponse } from 'axios';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const createPath = (base: string, stage?: string) => `${base}${stage}`;

const axiosInstance = axios.create({
	baseURL: HOST_API,
});

const SuccessInterceptor = (response: AxiosResponse<any, any>) => response.data.data;
const ErrorInterceptor = (error: any) =>
	Promise.reject((error.response && error.response.data) || 'Something went wrong');

axiosInstance.interceptors.response.use((response) => response, ErrorInterceptor);

export { axiosInstance };
