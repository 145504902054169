// @mui
import { enUS } from '@mui/material/locale';
// components
import { SettingsValueProps } from './components/settings/type';
// ENvironment
export const isDevEnv = process.env.REACT_APP_DEV_ENVIRONMENT || false;

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';
export const HOST_API_PROGRAM_EXPERIENCE =
  process.env.REACT_APP_HOST_API_KEY_PROGRAM_EXPERIENCE || '';

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};
// ROOT PATH AFTER LOGIN SUCCESSFUL
export const BASE_URL_AFTER_LOGIN =
  process.env.REACT_APP_PATH_AFTER_LOGIN || 'http://localhost:3001/';
export const ASSET_URL = process.env.REACT_APP_ASSET_URL;
export const ASSET_URL_IMAGE = `${ASSET_URL}/videoreach/images`;
export const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || 'localhost';
// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 60,
  MAIN_DESKTOP_HEIGHT: 80,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 0,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings: SettingsValueProps = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
];

export const defaultLang = allLangs[0]; // English

export const flowStatus = {
  draft: 'draft',
  active: 'active',
  live: 'live',
  retired: 'retired',
};

export const featureDisplayNames = {
  flow: 'Flow',
  response: 'Response',
  programs: 'Programs',
  // patients: 'Patients',
};

export const PRICING_TABLE_ID = process.env.REACT_APP_PRICING_TABLE_ID;
export const PUBLISHABLE_KEY = process.env.REACT_APP_PUBLISHABLE_KEY;

// Contact us

// salesforce
export const SALESFORCE_URL =
  'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
export const ORG_ID = '00DHs000000PzeV';
export const RECORD_TYPE = '012R7000000ZQZ7';
