import { ReactNode, createContext, useEffect, useReducer } from 'react';
import { ActionMap, AuthCoreContextType, AuthState, AuthUser } from '../@types/auth';
import { axiosInstance } from '../utils/axios';
import { getItem } from '../utils/cookies';
import { getUserFromToken, isValidToken, setSession } from '../utils/jwt';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Signup = 'SIGNUP',
  RefreshToken = 'REFRESHTOKEN',
}

type CoreAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    isSignupPending: boolean | null;
    isPaymentPending: boolean | null;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
    isSignupPending: boolean | null;
    isPaymentPending: boolean | null;
  };
  [Types.Logout]: undefined;
  [Types.Signup]: {
    message: string;
  };
  [Types.RefreshToken]: {
    user: AuthUser;
    isSignupPending: boolean | null;
    isPaymentPending: boolean | null;
  };
};

export type CoreAuthActions = ActionMap<CoreAuthPayload>[keyof ActionMap<CoreAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isSignupPending: null,
  isPaymentPending: null,
  user: null,
};

const CoreAuthReducer = (state: AuthState, action: CoreAuthActions) => {
  switch (action.type) {
    case Types.Initial:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        isSignupPending: action.payload.isSignupPending,
        isPaymentPending: action.payload.isPaymentPending,
        user: action.payload.user,
      };
    case Types.Login:
      return {
        ...state,
        isAuthenticated: true,
        isSignupPending: action.payload.isSignupPending,
        isPaymentPending: action.payload.isPaymentPending,
        user: action.payload.user,
      };
    case Types.Logout:
      return {
        ...state,
        isAuthenticated: false,
        isSignupPending: null,
        isPaymentPending: null,
        user: null,
      };
    case Types.Signup:
      return {
        ...state,
        isAuthenticated: false,
        isSignupPending: true,
        isPaymentPending: true,
        user: null,
      };
    case Types.RefreshToken:
      return {
        ...state,
        isAuthenticated: true,
        isSignupPending: action.payload.isSignupPending,
        isPaymentPending: action.payload.isPaymentPending,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

const AuthContext = createContext<AuthCoreContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(CoreAuthReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = getItem('accessToken');
        const idToken = getItem('idToken');
        const refreshToken = getItem('refreshToken');

        if (accessToken && isValidToken(accessToken) && idToken) {
          setSession(accessToken, idToken, refreshToken);

          const user = getUserFromToken(idToken);
          const isSignupPending = user?.shouldAddAttributes;
          const isPaymentPending = user?.isPaymentCompleted
            ? user?.isPaymentCompleted !== 'true'
            : true;

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              isSignupPending,
              isPaymentPending,
              user,
            },
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
              isSignupPending: null,
              isPaymentPending: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
            isSignupPending: null,
            isPaymentPending: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    const response = await axiosInstance.post('/api/login', {
      email,
      password,
    });

    const { AccessToken, IdToken, RefreshToken } = response.data.data;
    const user = getUserFromToken(IdToken);
    const isSignupPending = user?.shouldAddAttributes;
    const isPaymentPending = user?.isPaymentCompleted ? user?.isPaymentCompleted !== 'true' : true;
    setSession(AccessToken, IdToken, RefreshToken);

    dispatch({
      type: Types.Login,
      payload: {
        user,
        isSignupPending,
        isPaymentPending,
      },
    });
  };

  const signup = async (email: string, password: string) => {
    const response = await axiosInstance.post('/api/register', {
      email,
      password,
    });

    dispatch({
      type: Types.Signup,
      payload: {
        message: response.data,
      },
    });

    return response;
  };

  //add user attribute here
  const addUserAttribute = async (isPaymentCompleted: boolean, sessionId: string) => {
    const accessToken = getItem('accessToken');

    const response = await axiosInstance.post('/api/tenantSubscriptions', {
      accessToken,
      isPaymentCompleted: isPaymentCompleted.toString(),
      sessionId: sessionId,
    });

    return response;
  };

  //Check payment status
  const checkPaymentStatus = async (sessionId: string) => {
    const response = await axiosInstance.get(`/api/stripeSession/${sessionId}`);
    return response.data;
  };

  //add user attribute here
  const modifyUserAttribute = async (username: string, company: string, tenant: string) => {
    const accessToken = getItem('accessToken');
    const user = getUserFromToken(getItem('idToken'));

    const response = await axiosInstance.post('/api/userAttributes', {
      accessToken,
      email: user?.email,
      tenant: company,
      username,
      role: 'admin',
      domain: `${tenant}.videoreach.run`,
    });

    return response;
  };

  const logout = async () => {
    setSession(null, null, null);
    dispatch({ type: Types.Logout });
  };

  const refreshToken = async (refreshToken: string) => {
    const response = await axiosInstance.post('/api/refreshToken', {
      refreshToken,
    });

    const { AccessToken, IdToken, RefreshToken } = response.data.data;

    const user = getUserFromToken(IdToken);
    const isSignupPending = user?.shouldAddAttributes;
    const isPaymentPending = user?.isPaymentPending;

    setSession(AccessToken, IdToken, RefreshToken);

    dispatch({
      type: Types.RefreshToken,
      payload: {
        user,
        isSignupPending,
        isPaymentPending,
      },
    });
    return response;
  };

  const sendResetPasswordCode = async (email: string) => {
    const response = await axiosInstance.post('/api/forgotPassword', {
      email: email,
    });
    return response;
  };

  const resetPassword = async (email: string, code: string, password: string) => {
    const response = await axiosInstance.post('/api/confirmForgotPassword', {
      email,
      code,
      password,
    });
    return response;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'core',
        login,
        logout,
        addUserAttribute,
        modifyUserAttribute,
        signup,
        refreshToken,
        sendResetPasswordCode,
        resetPassword,
        isSignupPending: state.isSignupPending,
        isPaymentPending: state.isPaymentPending,
        checkPaymentStatus,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
