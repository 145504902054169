import { Box, Stack, styled } from '@mui/material';
import { Outlet } from 'react-router';
import Logo from '../../components/Logo';

const OUTLET_WIDTH_UP_SM = `526px`;

const AuthContainer = styled(Stack)(({ theme }) => ({
	minHeight: '100vh',
	justifyContent: 'center',
	alignItems: 'center',
	background: 'url(/assets/auth_bg.svg)',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
}));

const OutletContainer = styled(Box)(({ theme }) => ({
	borderRadius: Number(theme.shape.borderRadius) * 2,
	border: `1px solid transparent`,
	backgroundColor: theme.palette.background.paper,
	boxShadow: theme.customShadows.z8,
	padding: theme.spacing(5),
	[theme.breakpoints.up('sm')]: {
		width: OUTLET_WIDTH_UP_SM,
	},
}));

export default function AuthLayout() {
	return (
		<AuthContainer>
			<OutletContainer>
				<Stack sx={{ justifyContent: 'center', alignItems: 'center', mb: 2 }}>
					<Logo />
				</Stack>
				<Outlet />
			</OutletContainer>
		</AuthContainer>
	);
}
