import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container } from '@mui/material';
// components
import Page from '../components/Page';
import { MotionContainer } from '../components/animate';
import { axiosInstance } from '../utils/axios';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
}));

// ----------------------------------------------------------------------

export default function PatientUnsubscribe() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const phoneNumber = id ? encodeURIComponent(id) : '';

  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const handleUnsubscribe = async () => {
    try {
      const response = await axiosInstance.put(`api/patients/updateConsentStatus`, {
        phoneNumber: phoneNumber,
      });
      setUnsubscribeSuccess(true);
    } catch ({ error }) {
      setUnsubscribeSuccess(false);
    } finally {
      setLoading(false); // Update loading staten when api is completed.
    }
  };

  useEffect(() => {
    handleUnsubscribe();
  }, []);

  return (
    <Page title="Unsubscribe Patient">
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <>
              <Typography variant="h4" sx={{ mb: 2 }}>
                {unsubscribeSuccess
                  ? 'Successfully Unsubscribed. For subscribing again please contact the hospital authorities'
                  : 'Apologies! We faced an issue in unsubscribing you. Please retry again. Sorry for the inconvenience. If this issue persists, please call the associated sender to help you unsubscribe.'}
              </Typography>
              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Home
              </Button>
            </>
          )}
        </ContentStyle>
      </Container>
    </Page>
  );
}
