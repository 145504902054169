// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import NotistackProvider from './components/NotistackProvider';
import { ProgressBarStyle } from './components/ProgressBar';
import ScrollToTop from './components/ScrollToTop';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { ChartStyle } from './components/chart';
import ThemeSettings from './components/settings';
import { ButtonVisibilityProvider } from './contexts/DemoButtonContext';

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;
    const sectionId = hash.substring(1);

    if (hash) {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (hash && sectionId !== location.pathname) {
      window.history.replaceState(null, '', location.pathname);
    }
  }, [location]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ButtonVisibilityProvider>
              <ProgressBarStyle />
              <ChartStyle />
              <ScrollToTop />
              <Router />
            </ButtonVisibilityProvider>
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
