// @mui
import { BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router';
import VRLogo from '../assets/logo';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  isHome?: boolean;
}

const StyledDiv = styled('div')(({ theme }) => ({
  '& svg': {
    width: '181px',
    height: '32px',
    paddingRight: 8,
  },
  [theme.breakpoints.down('lg')]: {
    '& svg': {
      width: '135px',
      height: '24px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& svg': {
      width: '131px',
      height: '24px',
    },
  },
}));

export default function Logo({ disabledLink = false, isHome = false, sx }: Props) {
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isHome) {
      event.preventDefault();
      document.querySelector('body')?.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate('/');
    }
  };

  const logo = (
    <StyledDiv>
      <VRLogo />
    </StyledDiv>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <a href="/" onClick={handleClick}>
      {logo}
    </a>
  );
}
