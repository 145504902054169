import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// layouts
import MainLayout from '../layouts/main';
// guards
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import AuthGuard from 'src/guards/AuthGuard';
import PatientUnsubscribe from 'src/pages/PatientUnsubscribe';
import LoadingScreen from '../components/LoadingScreen';
import AuthLayout from '../layouts/auth';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/flow') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));

//REGISTRATION
const Signup = Loadable(lazy(() => import('../pages/register/signup')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const Setup = Loadable(lazy(() => import('../pages/register/setup')));
const Wait = Loadable(lazy(() => import('../pages/register/wait')));
const Payment = Loadable(lazy(() => import('../pages/register/payment')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const CookiePolicy = Loadable(lazy(() => import('../pages/CookiePolicy')));
const PrivacyPolicy = Loadable(lazy(() => import('../pages/PrivacyPolicy')));
const TermOfUse = Loadable(lazy(() => import('../pages/TermUse')));
const BookDemo = Loadable(lazy(() => import('../pages/BookDemo')));
const ContactUs = Loadable(lazy(() => import('../pages/ContactUs')));

const routes = [
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
      </AuthGuard>
    ),
    children: [{ element: <HomePage />, index: true }],
  },
  {
    path: 'auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: (
          <AuthGuard>
            <Login />
          </AuthGuard>
        ),
      },

      { path: 'reset-password', element: <ResetPassword /> },
      { path: 'new-password', element: <NewPassword /> },
    ],
  },
  {
    path: 'register',
    element: <AuthLayout />,
    children: [
      {
        element: <Signup />,
        index: true,
      },
      { path: 'verify', element: <VerifyCode /> },
      {
        path: 'setup',
        element: <Setup />,
      },
      {
        path: 'payment',
        element: <Payment />,
      },
    ],
  },
  {
    path: 'logout',
    element: (
      <AuthGuard>
        <Login isLogout={true} />
      </AuthGuard>
    ),
  },
  {
    path: 'wait',
    element: <Wait />,
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: 'logout',
        element: <Login isLogout={true} />,
      },
      { path: 'coming-soon', element: <ComingSoon /> },
      { path: 'maintenance', element: <Maintenance /> },
      { path: 'cookie-policy', element: <CookiePolicy /> },
      { path: 'term-of-use', element: <TermOfUse /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: '500', element: <Page500 /> },
      { path: '404', element: <Page404 /> },
      { path: '403', element: <Page403 /> },
      { path: 'patient/unsubscribe', element: <PatientUnsubscribe /> },
      { path: 'book-demo', element: <BookDemo /> },
      { path: 'contact-sale', element: <ContactUs /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default function Router() {
  return useRoutes(routes);
}
