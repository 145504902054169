import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import { AuthUser } from '../@types/auth';
import { axiosInstance } from './axios';
import { deleteItem, setItem } from './cookies';

// ----------------------------------------------------------------------

interface DecodedUser {
  'custom:Username': string;
  'custom:Role': string;
  'custom:Organization': string;
  'custom:Domain': string;
  'custom:isPaymentCompleted': string;
  email: string;
}

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number) => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  // const timeLeft = currentTime + 10000 - currentTime; // ~10s
  const timeLeft = exp * 1000 - currentTime;

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('idToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (
  accessToken: string | null,
  idToken: string | null,
  refreshToken: string | null
) => {
  if (accessToken && idToken && refreshToken) {
    setItem('accessToken', accessToken);
    setItem('refreshToken', refreshToken);
    setItem('idToken', idToken);

    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode<{ exp: number }>(accessToken);
    handleTokenExpired(exp);
  } else {
    deleteItem('accessToken');
    deleteItem('refreshToken');
    deleteItem('idToken');

    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

const getUserFromToken = (idToken: string): AuthUser | null => {
  if (!idToken) return null;

  const decodedUser: DecodedUser = jwtDecode(idToken);
  const hasAttributes = checkProperties(decodedUser, ['custom:Organization']);

  const decodeCallback = !hasAttributes ? decodeNewUser : decodeExistingUser;

  return decodeCallback(decodedUser);
};

const decodeExistingUser = (decodedUser: DecodedUser) => ({
  displayName: decodedUser['custom:Username'] || '',
  role: decodedUser['custom:Role'] || '',
  org: decodedUser['custom:Organization'] || '',
  domain: decodedUser['custom:Domain'] || '',
  isPaymentCompleted: decodedUser['custom:isPaymentCompleted'] || '',
  email: decodedUser.email,
  shouldAddAttributes: false,
});

const decodeNewUser = (decodedUser: DecodedUser) => ({
  email: decodedUser.email,
  isPaymentCompleted: decodedUser['custom:isPaymentCompleted'] || '',
  shouldAddAttributes: true,
});

const checkProperties = (obj: Record<string, any>, keysToCheck: Array<string>) => {
  let foundKeyCtr = 0;

  for (const objKey of Object.keys(obj)) {
    for (const key of keysToCheck) {
      if (key === objKey) foundKeyCtr++;
    }
  }

  return foundKeyCtr === keysToCheck.length;
};

export { getUserFromToken, isValidToken, setSession };
