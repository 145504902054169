import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Button(theme: Theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: theme.spacing(1),
          fontSize: '24px',
          fontWeight: 400,
          boxShadow: 'none',
          borderWidth: '2px',
          // color: theme.palette.common.white,
          '&:hover': {
            boxShadow: 'none',
            borderWidth: '2px',
          },
          [theme.breakpoints.down('md')]: {
            fontSize: '18px',
          },
        },
        sizeLarge: {
          height: theme.spacing(7.5),
          paddingLeft: theme.spacing(3.5),
          paddingRight: theme.spacing(3.5),
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
          [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2.5),
            paddingTop: theme.spacing(1.25),
            paddingBottom: theme.spacing(1.25),
            fontSize: '20px',
          },
        },
        sizeSmall: {
          height: theme.spacing(5.75),
          fontSize: '20px',
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          paddingTop: theme.spacing(1.25),
          paddingBottom: theme.spacing(1.25),
          [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
          },
        },
        // contained
        containedPrimary: {
          '&:disabled': {
            background: theme.palette.grey[500],
            color: theme.palette.common.white,
          },
        },
        // secondaryButton
        containedSecondary: {
          boxShadow: 'none',
          color: theme.palette.special.neutralBlack,
          backgroundColor: theme.palette.special.teal,
          '&:hover': {
            border: 'none',
            boxShadow: 'none',
            backgroundColor: theme.palette.special.teal,
            color: theme.palette.primary.main,
          },
          '&:disabled': {
            color: theme.palette.grey[500],
            border: `1px solid ${theme.palette.grey[500]}`,
            background: theme.palette.common.white,
          },
        },
        containedInherit: {
          color: theme.palette.grey[800],
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: theme.palette.grey[400],
          },
        },
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      },
    },
  };
}
