import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Input(theme: Theme) {
  return {
    MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       color: theme.palette.special.neutralBlack,
      //       '&.Mui-disabled': {
      //         '& svg': { color: theme.palette.text.disabled },
      //       },
      //     },
      input: {
        padding: '2.25 2.5',
        // '&::placeholder': {
        //   opacity: 1,
        //   color: theme.palette.grey[700],
        // },
      },
      //   },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          border: '1px solid #C0C0C0',
          // padding: '0px 0px 0px 20px !important',
          marginBottom: '25px',
          borderRadius: '8px',
          background: theme.palette.common.white,
        },
      },
    },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: theme.palette.grey[500_12],
    //       '&:hover': {
    //         backgroundColor: theme.palette.grey[500_16],
    //       },
    //       '&.Mui-focused': {
    //         backgroundColor: theme.palette.action.focus,
    //       },
    //       '&.Mui-disabled': {
    //         backgroundColor: theme.palette.action.disabledBackground,
    //       },
    //     },
    //     underline: {
    //       '&:before': {
    //         borderBottomColor: theme.palette.grey[700],
    //       },
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // height: '60px',
          borderRadius: theme.spacing(1),
          background: theme.palette.common.white,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey[600],
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[600],
            },
          },
          '&.Mui-focused': {
            borderColor: theme.palette.grey[600],
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: theme.palette.grey[600],
            },
          },
        },
        input: {
          padding: '2.25 2.5',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          lineHeight: '36.83px',
          [theme.breakpoints.down('md')]: {
            lineHeight: '27.83px',
          },
          color: theme.palette.grey[700],
          '&.Mui-focused': {
            color: theme.palette.special.neutralBlack,
            lineHeight: '27.83px',
          },
        },
      },
    },
  };
}
