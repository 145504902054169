import { pxToRem, responsiveFontSizes } from '../utils/getFontValue';

// ----------------------------------------------------------------------

const FONT_PRIMARY = `'Lora', serif`; // Google Font
const FONT_SECONDARY = `'Source Sans Pro' , sans-serif`;

const typography = {
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 1000,
  h1: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '112.5%',
    fontSize: 64,
    letterSpacing: '0.32px',
    ...responsiveFontSizes({ sm: 32, md: 42, lg: 64 }),
  },
  h2: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '114.583%',
    fontSize: 48,
    letterSpacing: '0.24px',
    ...responsiveFontSizes({ sm: 28, md: 32, lg: 48 }),
  },
  h3: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '125%',
    fontSize: 32,
    letterSpacing: '0.16px',
    ...responsiveFontSizes({ sm: 24, md: 26, lg: 32 }),
  },
  h4: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 500,
    lineHeight: '133.333%',
    fontSize: 24,
    letterSpacing: '0.12px',
    ...responsiveFontSizes({ sm: 20, md: 22, lg: 24 }),
  },
  h5: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: '130%',
    fontSize: 20,
    letterSpacing: '0.1px',
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 20 }),
  },
  h6: {
    fontFamily: FONT_PRIMARY,
    fontWeight: 400,
    lineHeight: '125%',
    fontSize: 18,
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
    fontFamily: FONT_SECONDARY,
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontFamily: FONT_SECONDARY,
    fontSize: pxToRem(14),
  },
  body1: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: '141.667%',
    fontSize: 24,
    letterSpacing: '0.048px',
    ...responsiveFontSizes({ sm: 18, md: 20, lg: 24 }),
  },
  body2: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: '140%',
    fontSize: 18,
    letterSpacing: '0.048px',
    ...responsiveFontSizes({ sm: 16, md: 18, lg: 18 }),
  },
  body3: {
    fontFamily: FONT_SECONDARY,
    fontWeight: 400,
    lineHeight: '139.667%',
    fontSize: 16,
    letterSpacing: '0.048px',
    ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: 12,
    fontFamily: FONT_SECONDARY,
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
    fontFamily: FONT_SECONDARY,
  },
  button: {
    fontFamily: FONT_SECONDARY,
  },
} as const;

export default typography;
