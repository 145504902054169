import { Outlet, useOutletContext } from 'react-router-dom';
// @mui
import { Box, Stack } from '@mui/material';
// components
//
import useToggle from '../../hooks/useToggle';
import MainFooter from './MainFooter';
import MainHeader from './MainHeader';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { toggle: open, onOpen, onClose } = useToggle(false);

  return (
    <Stack sx={{ minHeight: 1 }}>
      <MainHeader />

      <Outlet
        context={{
          onOpen,
        }}
      />

      <Box sx={{ flexGrow: 1 }} />

      <MainFooter />
    </Stack>
  );
}

type ContextType = {
  onOpen: VoidFunction;
};

export function useContactModal() {
  return useOutletContext<ContextType>();
}
