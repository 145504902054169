import { ReactNode, createContext, useContext, useState } from 'react';

interface ButtonVisibilityContextType {
  isButtonVisible: boolean;
  toggleButtonVisibility: (visibility: boolean) => void;
}

type ButtonVisibilityProps = {
  children: ReactNode;
};
// ----------------------------------------------------------------------
const initialContext: ButtonVisibilityContextType = {
  isButtonVisible: false,
  toggleButtonVisibility: () => {},
};

const ButtonVisibilityContext = createContext<ButtonVisibilityContextType>(initialContext);

function ButtonVisibilityProvider({ children }: ButtonVisibilityProps) {
  const [isButtonVisible, setIsButtonVisible] = useState<boolean>(false);

  const toggleButtonVisibility = (visibility: boolean) => {
    setIsButtonVisible(visibility);
  };

  return (
    <ButtonVisibilityContext.Provider value={{ isButtonVisible, toggleButtonVisibility }}>
      {children}
    </ButtonVisibilityContext.Provider>
  );
}

export { ButtonVisibilityContext, ButtonVisibilityProvider };

export const useButtonVisibility = () => useContext(ButtonVisibilityContext);
