// @mui
import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
// components
import { Link, useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import useResponsive from 'src/hooks/useResponsive';
import HPLogoWhite from '../../assets/logo-white';
import { PATH_PAGE } from '../../routes/paths';
import { pxToRem } from '../../utils/getFontValue';

// ----------------------------------------------------------------------
const ICON_SIZE = {
  width: 20,
  height: 20,
};

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  background: theme.palette.special.teal,
  color: theme.palette.special.neutralBlack,
  boxShadow: 'none',
  '&:hover': {
    background: theme.palette.special.teal,
    color: theme.palette.primary.main,
  },
}));

const StyledListItem = styled('li')(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  '& > a': {
    color: theme.palette.common.white,
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 400,
    paddingRight: theme.spacing(0.5),
  },
}));

const policyNavLinks = [
  {
    text: 'Cookie Policy',
    path: 'cookie-policy',
  },
  {
    text: 'Privacy Policy',
    path: 'privacy-policy',
  },
  {
    text: 'Term of Use',
    path: 'term-of-use',
  },
  {
    text: 'Support',
    path: 'mailto:support@healthipeople.com',
  },
];

const socialNavLinks = [
  {
    icon: 'mdi:linkedin',
    target: '_blank',
    path: 'https://www.linkedin.com/company/healthipeople/',
  },
  {
    icon: 'ic:baseline-tiktok',
    target: '_blank',
    path: 'https://www.tiktok.com/@healthipeople',
  },
  {
    icon: 'mdi:facebook',
    target: '_blank',
    path: 'https://www.facebook.com/profile.php?id=100090266781094',
  },
];

export default function MainFooter() {
  const theme = useTheme();

  const navigate = useNavigate();

  const isMobile = useResponsive('down', 'md');

  return (
    <Box
      sx={{
        p: { xs: 2 },
        pt: 8,
        bgcolor: theme.palette.special.darkGreen,
        color: theme.palette.common.white,
      }}
    >
      <Grid container rowSpacing={2}>
        {/* Row 1 starts */}
        {/* Filler columns */}
        <Grid item sm={2}>
          {''}
        </Grid>

        {/* Logo */}
        <Grid item sm={3} xs={12} sx={{ pt: { md: '48px !important', xs: 0 } }}>
          <HPLogoWhite />
          <Typography
            variant="body2"
            sx={{ pb: { md: '2px !important', xs: 0 }, pt: { md: '18px !important', xs: 0 } }}
          >
            Building healthier societies across generations
          </Typography>
          <StyledButton variant="contained" size="small" onClick={() => navigate(PATH_PAGE.demo)}>
            Book a demo
          </StyledButton>
        </Grid>

        {/* Resources */}
        <Grid item sm={5} xs={12} sx={{ pt: { md: '48px !important', xs: 0 } }}>
          <Typography variant="h6" color={'primary.light'} sx={{ pb: 2 }}>
            Resources
          </Typography>
          <ul style={{ listStyle: 'none' }}>
            <StyledListItem>
              <Link to="https://www.healthipeople.com/about-us" target="_blank">
                About Us{' '}
              </Link>
              <Iconify icon={'material-symbols:arrow-outward'} {...ICON_SIZE} color={'primary'} />
            </StyledListItem>
            <StyledListItem>
              <Link to="https://www.healthipeople.com" target="_blank">
                Explore Healthipeople{' '}
              </Link>
              <Iconify icon={'material-symbols:arrow-outward'} {...ICON_SIZE} color={'primary'} />
            </StyledListItem>
            <StyledListItem>
              <Link to="mailto:support@healthipeople.com">Contact</Link>
            </StyledListItem>
            <StyledListItem>
              <Link to="https://videoreach.notiondesk.help/en" target="_blank">
                FAQ
              </Link>
              <Iconify icon={'material-symbols:arrow-outward'} {...ICON_SIZE} color={'primary'} />
            </StyledListItem>
          </ul>
        </Grid>

        {/* Filler */}
        <Grid item sm={2}>
          {''}
        </Grid>
        {/* Row 1 ends */}

        {/* Row 2 starts */}
        <Grid item sm={2}>
          {''}
        </Grid>
        <Grid item sm={8} xs={10}>
          <Divider sx={{ borderStyle: 'solid', my: 3 }} />
        </Grid>
        <Grid item sm={2}>
          {''}
        </Grid>
        {/* Row 2 ends */}

        {/* Row 3 starts */}
        <Grid item sm={2}>
          {''}
        </Grid>
        <Grid item sm={7} xs={12} sx={{ pt: { md: 2.25, xs: 0 } }}>
          <List
            sx={{
              display: 'flex',
              pt: 0,
            }}
            component={Stack}
            direction={isMobile ? 'column' : 'row'}
          >
            <Typography
              variant="caption"
              component="p"
              sx={{
                color: 'common.white',
                fontSize: pxToRem(16),
                lineHeight: 1,
                marginTop: 1,
                marginRight: '1rem',
                pb: { xs: 1 },
              }}
            >
              HealthiPeople &copy; {new Date().getFullYear()}
            </Typography>
            {policyNavLinks.map((navLink, index) => (
              <ListItem
                key={navLink.path}
                sx={{
                  paddingRight: 2,
                  paddingLeft: { xs: 0 },
                  width: 'auto',
                  color: ' #FFFFFF80',
                  fontSize: pxToRem(16),
                  lineHeight: 1,
                  '&:last-child': { paddingRight: 0 },
                }}
                to={navLink.path}
                component={Link}
                alignItems="flex-start"
              >
                {navLink.text}
              </ListItem>
            ))}
          </List>
        </Grid>
        {/* <Grid item md={3}>
          {''}
        </Grid> */}
        <Grid item sm={1} sx={{ pt: { md: 2.25, xs: 0 } }}>
          <List component={Stack} direction={'row'} justifyContent="flex-end" sx={{ py: 0, pt: 0 }}>
            {socialNavLinks.map((navLink) => (
              <Box
                key={navLink.path}
                sx={{
                  borderRadius: theme.spacing(1),
                  bgcolor: '#FFFFFF33',
                  marginLeft: { md: 2, xs: 0 },
                  marginRight: { md: 0, xs: 2 },
                  py: 0,
                }}
              >
                <ListItem
                  sx={{
                    padding: 1,
                    width: 'auto',
                    color: 'common.white',
                    fontSize: pxToRem(16),
                  }}
                  to={navLink.path}
                  component={Link}
                  target={navLink.target}
                  alignItems="flex-start"
                >
                  <Iconify icon={navLink.icon} width={22} height={22} />
                </ListItem>
              </Box>
            ))}
          </List>
        </Grid>

        {/* Row 3 ends */}
      </Grid>
    </Box>
  );
}
