import { COOKIE_DOMAIN } from '../config';

const getItem = (key: string) =>
	document.cookie.split('; ').reduce((total, currentCookie) => {
		const item = currentCookie.split('=');
		const storedKey = item[0];
		const storedValue = item[1];

		return key === storedKey ? decodeURIComponent(storedValue) : total;
	}, '');

const setItem = (
	key: string,
	value: string,
	numberOfDays: number = 1,
	domain: string = COOKIE_DOMAIN
) => {
	const now = new Date();

	// set the time to be now + numberOfDays
	now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);
	document.cookie = `${key}=${value};expires=${now.toUTCString()};path=/;domain=${domain}`;
};

const deleteItem = (name: string, domain: string = COOKIE_DOMAIN, path: string = '/') => {
	if (getItem(name)) {
		document.cookie =
			name +
			'=' +
			(path ? ';path=' + path : '') +
			(domain ? ';domain=' + domain : '') +
			';expires=Thu, 01 Jan 1970 00:00:01 GMT';
	}
};

export { deleteItem, getItem, setItem };
