// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_SIGNUP = '/register';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  forgotPassword: path(ROOTS_AUTH, '/forgot-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_SIGNUP = {
  root: ROOTS_SIGNUP,
  verify: path(ROOTS_SIGNUP, '/verify'),
  setup: path(ROOTS_SIGNUP, '/setup'),
  payment: path(ROOTS_SIGNUP, '/payment'),
  wait: '/wait',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  demo: '/book-demo',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  patientUnsubscribe: '/patient/unsubscribe',
  contactSale: '/contact-sale',
};
