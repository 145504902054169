import { ASSET_URL } from '../config';

export type ItemRootType = {
  [key: string]: string;
};

export type language = {
  id: string;
  label: string;
};

export const languages: Array<language> = [
  {
    id: 'english',
    label: 'English',
  },
  {
    id: 'spanish',
    label: 'Spanish',
  },
];

export const DEFAULT_ORGANISATION = 'dev';
export const USER_ATTRIBUTE_ORGANISATION_KEYNAME = 'custom:Organization';

type AssetType = 'image' | 'video';

export const constructAssetPath = (type: AssetType, path: string) => {
  const BASE_ASSETURL = `${ASSET_URL}/videoreach`;
  const folder = type === 'image' ? 'images' : 'videos';

  return `${BASE_ASSETURL}/${folder}/${path}`;
};

export const SHEET_DB_URL = process.env.REACT_APP_SHEETDB_URL || 'https://sheetdb.io/api/v1/';
export const SHEET_DB_KEY_CONTACT = process.env.REACT_APP_SHEETDB_API_KEY_CONTACT_US || '';
export const SHEET_DB_KEY_BOOK_DEMO = process.env.REACT_APP_SHEETDB_API_KEY_BOOK_DEMO || '';
