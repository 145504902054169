import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import { AppBar, Box, Button, Drawer, Grid, Link, Toolbar, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { BASE_URL_AFTER_LOGIN, HEADER } from '../../config';
// components
import { common } from '@mui/material/colors';
import Logo from '../../components/Logo';
import { DEFAULT_ORGANISATION } from '../../constants';
import useAuth from '../../hooks/useAuth';
import { PATH_AUTH, PATH_PAGE, PATH_SIGNUP } from '../../routes/paths';
// import { Icon } from '@iconify/react';
import Iconify from '../../components/Iconify';
import { useButtonVisibility } from '../../contexts/DemoButtonContext';

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MAIN_DESKTOP_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.down('sm')]: {
    height: HEADER.MOBILE_HEIGHT,
  },
}));

const links = [
  // { text: 'Product', url: '/product' },
  // { text: 'Pricing', url: '/pricing' },
  // { text: 'Examples', url: '/examples' },
  { text: 'FAQ', url: 'https://videoreach.notiondesk.help/en', isExternal: true },
  { text: 'Contact us', url: '/contact-sale' },
];

const ButtonStyle = styled(Button)(({ theme }) => ({
  // textTransform: 'none',
  // marginLeft: theme.spacing(4),
  // marginRight: theme.spacing(1),
  // color: theme.palette.common.black,
  // borderColor: 'white',
  // backgroundColor: theme.palette.special.teal,
  // [theme.breakpoints.down('sm')]: {
  //   marginLeft: theme.spacing(0),
  // },
}));

const ButtonDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    paddingLeft: theme.spacing(5),
  },
  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(4),
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(0),
  },
}));

// ----------------------------------------------------------------------

export default function MainHeader() {
  const { isAuthenticated, user, isSignupPending, isPaymentPending } = useAuth();

  const after_login_url = BASE_URL_AFTER_LOGIN.replace(DEFAULT_ORGANISATION, user?.org);

  const { isButtonVisible: isHeroDemoVisible } = useButtonVisibility();

  const theme = useTheme();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const isHome = pathname === '/';

  console.log(isPaymentPending);

  const auth_path =
    isAuthenticated && isPaymentPending
      ? PATH_SIGNUP.payment
      : isAuthenticated && isSignupPending
      ? PATH_SIGNUP.setup
      : after_login_url;

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [shouldShowBookDemo, setShouldShowBookDemo] = useState(isDesktop || isHeroDemoVisible);

  const toggleDrawer = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    setShouldShowBookDemo(isDesktop || isHeroDemoVisible);
  }, [isDesktop, isHeroDemoVisible]);

  const handleLinkClick = () => {
    setDrawerOpen(false);
  };

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: common.white }}>
      <ToolbarStyle disableGutters>
        <Grid container>
          <Grid item xs={false} sm={false} md={1}>
            {''}
          </Grid>
          <Grid
            item
            xs={5}
            sm={4}
            md={4}
            display={'flex'}
            alignItems="center"
            justifyContent="flex-start"
            pl={{ xs: 3, md: 1 }}
          >
            <Logo isHome={isHome} />
          </Grid>
          <Grid
            item
            xs={7}
            sm={8}
            md={6}
            display={'flex'}
            alignItems="center"
            justifyContent="flex-end"
          >
            {isDesktop && (
              <>
                {links.map((link) => (
                  <Typography
                    key={link.text}
                    variant="body2"
                    component={RouterLink}
                    to={link.url}
                    target={link.isExternal ? '_blank' : '_self'}
                    sx={{
                      color: theme.palette.special.neutralBlack,
                      textDecoration: 'none',
                      marginLeft: theme.spacing(5),
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    {link.text}
                  </Typography>
                ))}
              </>
            )}
            {shouldShowBookDemo && (
              <ButtonDiv>
                <ButtonStyle
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => navigate(PATH_PAGE.demo)}
                  sx={{ ml: 2 }}
                >
                  Book a demo
                </ButtonStyle>
              </ButtonDiv>
            )}
            {!isDesktop && (
              <Box sx={{ px: 2, pt: 1 }}>
                <Iconify
                  icon={isDrawerOpen ? 'ph:x' : 'ic:round-menu'}
                  color="black"
                  onClick={toggleDrawer}
                  sx={{
                    fontSize: '24px',
                    cursor: 'pointer',
                    color: theme.palette.common.black,
                  }}
                />
              </Box>
            )}
            {isDesktop && isAuthenticated && (
              <Link
                component={RouterLink}
                variant="subtitle2"
                to={auth_path}
                sx={{ paddingY: 1, paddingLeft: 3 }}
              >
                Go to Dashboard
              </Link>
            )}
            {isDesktop && !isAuthenticated && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                component={RouterLink}
                to={PATH_AUTH.login}
                sx={{ ml: 2 }}
              >
                Sign in
              </Button>
            )}
          </Grid>
        </Grid>
      </ToolbarStyle>
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        PaperProps={{ style: { marginTop: '64px' } }}
      >
        {/* Add your menu items here */}
        {links.map((link) => (
          <Typography
            key={link.text}
            variant="body2"
            component={RouterLink}
            to={link.url}
            target={link.isExternal ? '_blank' : '_self'}
            sx={{
              color: theme.palette.special.neutralBlack,
              textDecoration: 'none',
              padding: theme.spacing(3),
            }}
            onClick={handleLinkClick}
          >
            {link.text}
          </Typography>
        ))}
      </Drawer>
    </AppBar>
  );
}
